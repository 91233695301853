import React, { useState, useEffect } from "react";
import "./App.css";

function App() {
  const [serverStatuses, setServerStatuses] = useState({
    Minecraft: "offline",
    Valheim: "offline",
    FiveM: "offline",
    Necesse: "offline",
  });
  const [activeTab, setActiveTab] = useState("Twitch");

  useEffect(() => {
    async function fetchServerStatuses() {
      try {
        const response = await fetch(
          "https://gaming.rjfbago.pt:7000/server-statuses"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setServerStatuses(data);
      } catch (error) {
        console.error("Error fetching server statuses:", error);
      }
    }

    fetchServerStatuses();
    const intervalId = setInterval(fetchServerStatuses, 30000); // Fetch every 30 seconds
    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  useEffect(() => {
    if (activeTab === "Twitch") {
      const script = document.createElement("script");
      script.src = "https://embed.twitch.tv/embed/v1.js";
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        const vw = Math.max(
          document.documentElement.clientWidth || 0,
          window.innerWidth || 0
        );
        const embedWidth = (vw * 80) / 100; // 80vw
        const embedHeight = (embedWidth * 9) / 16; // Maintain 16:9 aspect ratio

        if (!document.getElementById("twitch-embed").innerHTML) {
          new window.Twitch.Embed("twitch-embed", {
            width: embedWidth.toString(),
            height: embedHeight.toString(),
            channel: "bakk0s",
            parent: ["rjfbago.pt"],
            layout: "video-with-chat",
          });
        }
      };

      return () => {
        document.body.removeChild(script);
        const twitchEmbed = document.getElementById("twitch-embed");
        if (twitchEmbed) {
          twitchEmbed.innerHTML = "";
        }
      };
    }
  }, [activeTab]);

  return (
    <div className="App">
      {/* Header Left (Logo and Socials) */}
      <div className="header-left">
        <img src="/IMG/Bakk-w.png" className="logo" alt="Logo" />
        <div className="socials">
          <a href="https://www.facebook.com/Bakk0s">
            <img src="/IMG/facee.png" alt="Facebook" width="30px" height="30px" />
          </a>
          <a href="https://twitter.com/Bakko0s">
            <img src="/IMG/twiti.png" alt="Twitter" width="30px" height="30px" />
          </a>
          <a href="https://www.instagram.com/bakk0s/">
            <img src="/IMG/instaa.png" alt="Instagram" width="30px" height="30px" />
          </a>
          <a href="https://www.youtube.com/channel/UCM0MyfVNoSQbxuURArs8kZg">
            <img src="/IMG/yt.png" alt="YouTube" width="30px" height="30px" />
          </a>
          <a href="https://www.twitch.tv/bakk0s">
            <img
              src="/IMG/Twitch Purple Flat Circular App Icon PNG - 512x512.png"
              alt="Twitch"
              width="30px"
              height="30px"
            />
          </a>
          <a href="https://ricardojfbago.pt">
            <img src="/IMG/web.png" alt="Website" width="30px" height="30px" />
          </a>
        </div>
      </div>

      {/* Header Right (Join Discord Box) */}
      <div className="header-right">
        <div className="discord-box">
          <h3>Join our Discord</h3>
          <a href="https://discord.gg/HnpAz8j" className="join-button">
            JOIN
          </a>
        </div>
      </div>

      {/* Tabs */}
      <div className="tabs">
        <button
          className={`tab-button ${activeTab === "Twitch" ? "active" : ""}`}
          onClick={() => setActiveTab("Twitch")}
        >
          <img
            src="/IMG/twitch_logo.png" // Placeholder for Twitch logo
            alt="Twitch Logo"
            width="120"
            height="30"
          />
        </button>
        <button
          className={`tab-button ${activeTab === "Kick" ? "active" : ""}`}
          onClick={() => setActiveTab("Kick")}
        >
          <img
            src="/IMG/Kick_logo.png" // Placeholder for Kick logo
            alt="Kick Logo"
            width="120"
            height="30"
          />
        </button>
        <button
          className={`tab-button ${activeTab === "Youtube" ? "active" : ""}`}
          onClick={() => setActiveTab("Youtube")}
        >
          <img
            src="/IMG/YouTube_logo.png" // Placeholder for YouTube logo
            alt="YouTube Logo"
            width="120"
            height="30"
          />
        </button>
      </div>

      {/* Platform Content */}
      <div className="platform-content">
        {activeTab === "Twitch" && (
          <div id="twitch-embed" className="twitch-container"></div>
        )}
        {activeTab === "Kick" && (
          <div className="kick-container">
            <p>Kick content will go here.</p>
          </div>
        )}
        {activeTab === "Youtube" && (
          <div className="youtube-container">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/live_stream?channel=UCM0MyfVNoSQbxuURArs8kZg/live"
              frameBorder="0"
              allowFullScreen
              title="YouTube Live Stream"
            ></iframe>
          </div>
        )}
      </div>

      {/* Game Servers Section */}
      <section className="game-servers">
        <h2>Game Servers</h2>
        {/* Minecraft Server */}
        <div className="server-box">
          <img
            src="IMG/minecraft.png"
            alt="Minecraft"
            className="server-image"
          />
          <div className="server-details">
            <h3>Minecraft</h3>
            <div
              className={`status-light ${
                serverStatuses.Minecraft === "online" ? "green" : "red"
              }`}
              id="minecraft-status"
            ></div>
          </div>
          <a href="minecraft://conquer-it.pt:25565" className="play-button">
            PLAY
          </a>
        </div>
        {/* Valheim Server */}
        <div className="server-box">
          <img src="IMG/valheim.png" alt="Valheim" className="server-image" />
          <div className="server-details">
            <h3>Valheim</h3>
            <div
              className={`status-light ${
                serverStatuses.Valheim === "online" ? "green" : "red"
              }`}
              id="valheim-status"
            ></div>
          </div>
          <a href="valheim://130.185.118.52:2456" className="play-button">
            PLAY
          </a>
        </div>
        {/* FiveM Server */}
        <div className="server-box">
          <img src="IMG/fivem.png" alt="FiveM" className="server-image" />
          <div className="server-details">
            <h3>FiveM</h3>
            <div
              className={`status-light ${
                serverStatuses.FiveM === "online" ? "green" : "red"
              }`}
              id="fivem-status"
            ></div>
          </div>
          <a href="fivem://130.185.118.52:30120" className="play-button">
            PLAY
          </a>
        </div>
        {/* Necesse Server */}
        <div className="server-box">
          <img src="IMG/necesse.png" alt="Necesse" className="server-image" />
          <div className="server-details">
            <h3>Necesse</h3>
            <div
              className={`status-light ${
                serverStatuses.Necesse === "online" ? "green" : "red"
              }`}
              id="necesse-status"
            ></div>
          </div>
          <a href="necesse://130.185.118.52:14159" className="play-button">
            PLAY
          </a>
        </div>
      </section>
    </div>
  );
}

export default App
